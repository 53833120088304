import request from "./request";

const colUrl = function (uri) {
    return '/collocation' + uri
}

let api = {
    url(url) {
        return process.env.VUE_APP_API_URL + url
    },
    colUrl(url) {
        return process.env.VUE_APP_API_URL + '/collocation' + url
    },
    postCourseUpdatePosterInfo(data, cbk) {
        return request.post(colUrl('/course/update-poster-info'), data, cbk)
    },
    getMyOutfits(filter, callback) {
        return request.get(colUrl('/scheme/my-list'), filter, callback);
    },
    getOutfits(filter, callback) {
        return request.get(colUrl('/scheme/list'), filter, callback);
    },
    getGoods(filter, callback) {
        return request.get(colUrl('/goods/list'), filter, callback);
    },
    getCategories(callback) {
        return request.get(colUrl('/goods/categories'), {}, callback);
    },
    getGoodsCollects(filter, callback) {
        return request.get(colUrl('/goods/collect'), filter, callback);
    },
    goodsCollect(id, callback) {
        return request.post(colUrl('/goods/collect'), {id}, callback);
    },
    getOutfitDetail(id, callback) {
        return request.get(colUrl('/scheme/detail'), {id}, callback);
    },
    createOutfit(data, callback) {
        return request.post(colUrl('/scheme'), data, callback);
    },
    getMemberInfo(account, callback) {
        return request.get(colUrl('/member/info'), {account}, callback)
    },
    createGoodsUrl(params, callback) {
        return request.post(colUrl('/url'), params, callback)
    },
    get(uri, params, callback) {
        return request.get(colUrl(uri), params, callback);
    },
    post(uri, params, callback) {
        return request.post(colUrl(uri), params, callback);
    },
    _get(uri, params, callback) {
        return request.get(uri, params, callback);
    },
    _post(uri, params, callback) {
        return request.post(uri, params, callback);
    }
}


export default api;